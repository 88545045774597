<!-- nav bar -->

<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="../../assets//img/arthnext_logo.png" alt="ArthSetu">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto"> <!-- ms-auto should push the links to the right -->
        <li class="nav-item">
          <a id="tsts1" class="nav-link waves-light tst tst1" (click)="scrollToElement(home1)" routerLink="/"
          routerLinkActive mdbWavesEffect>Home</a>
        </li>
        <li class="nav-item">
          <a id="tsts2" class="nav-link waves-light tst2 tst" (click)="scrollToElement(product1)" routerLink="/"
              routerLinkActive mdbWavesEffect>Product</a>
        </li>
        <li class="nav-item">
          <a id="tsts3" class="nav-link waves-light tst3 tst" mdbPageScroll (click)="scrollToElement(customer1)" routerLink="/"
               mdbWavesEffect>Customer</a>
        </li>
        <li class="nav-item">
          <a id="tsts4" class="nav-link waves-light tst tst4" mdbPageScroll (click)="scrollToElement(contactus1)" routerLink="/"
               mdbWavesEffect>Contact Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-light"
          style="background-color: #e53c09; color: wheat !important; border-radius: 20px; font-size: small;"
           routerLinkActive="active" (click)="openDialogWithoutRef()" mdbWavesEffect>SCHEDULE DEMO</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- end nav bar -->


<ng-template #scheduledemo style="margin-top: 70px !important; ">
  <button mat-icon-button class="close-button " [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <div class="container " style="max-width: 500px !important;">


    <div class="form-header" style="text-align: center;">
      <h3 class="mt-2"> Write to us</h3>
    </div>
    <p class="dark-grey-text" style="text-align: center;">We love to hear from you!</p>
    <!-- Body -->

    <form #updateprod='ngForm'>


      <mat-dialog-content>

        <div class="card-body " style="align-content: center;">
          <div class="row">
            <div class="col-md-12">

              <form #login="ngForm">

                <div class="row">
                  <mat-form-field class="col-md-6">
                    <input name="name" ngModel required matInput placeholder="Your Name">
                  </mat-form-field>


                  <mat-form-field class="col-md-6">
                    <input name="email" ngModel required matInput placeholder="Your email">
                  </mat-form-field>



                  <mat-form-field class="col-md-12">
                    <input name="contact" type="tel" maxlength="10" minlength="10" ngModel required matInput
                      placeholder="Your Contact">
                  </mat-form-field>



                  <mat-form-field class="col-md-12">
                    <input name="subject" ngModel required matInput placeholder="Subject">
                  </mat-form-field>



                  <mat-form-field class="example-full-width" class="col-md-12">
                    <mat-label>Leave a Message</mat-label>
                    <textarea name="msg" ngModel required matInput placeholder="Ex. It makes me feel..."></textarea>
                  </mat-form-field>


                  <div class="text-center">
                    <button type="button" class="btn btn- primary-color-dark" [disabled]="!login.valid"
                      (click)="submitSchedule(login.value);login.reset()">Submit</button>
                  </div>

                </div>
              </form>
            </div>

          </div>

        </div>
      </mat-dialog-content>
    </form>


  </div>


</ng-template>




<!-- Begin Home Page -->

<div id="carouselExampleFade" #home1 class="carousel slide carousel-fade topmargin" data-ride="carousel" style="margin-top: -5%;">
  <div class="carousel-inner">
    <div class="carousel-item active" id="iim">
      <img  width="100%" height="auto"
        src="https://res.cloudinary.com/dsosgrerg/image/upload/v1619105759/ArthSethu/you-x-ventures-6awfTPLGaCE-unsplash_zzwoce_1_evaov91_2_kzj4bc.jpg"
        alt="First slide">
    </div>
    <div class="carousel-item" id="iim">
      <img  width="100%" height="auto"
        src="https://res.cloudinary.com/dsosgrerg/image/upload/v1619101967/ArthSethu/you-x-ventures-nF0nQuqBsrI-unsplash_tzilmk_1_qctniw92_maeukq.jpg"
        alt="Second slide">
    </div>
    <div class="carousel-item" id="iim">
      <img  width="100%" height="auto"
        src="https://res.cloudinary.com/dsosgrerg/image/upload/v1619100305/ArthSethu/you-x-ventures-vbxyFxlgpjM-unsplash_wuetw0_1_q4uwhf1_vptdar.jpg"
        alt="Third slide">
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
    <span aria-hidden="true"><i class="material-icons">
        arrow_left
      </i></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
    <span aria-hidden="true"><i class="material-icons">
        arrow_right
      </i></span>
    <span class="sr-only">Next</span>
  </a>
</div>



<main id="main" style="margin-top: -3%;">
  <!--NextGen Skill Development Section -->
  <section id="about" class="about">
    <div class="container">
      <!-- <a class="btn btn-white-view" [routerLink]="[ '/detailView',12345]">view</a> -->
      <div class="section-title ">
        <h2> <a class="effect-underline wow fadeIn slower delay-0.5s" id="underline-small">Arthnext</a></h2> <br>
        <p class="wow fadeInLeft slower delay-0.5s">Arthnext is a preferred FinTech Partner in growth for financial sector services in lending industry. With strong domain understanding, Arthnext team brings forward comprehensive, next-generation AI/ML enabled lending solution built on an advanced technology and analytical platform, designed to power the Business in lending for building next level partnerships with customers.
        </p>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <img width="100%" height="auto"
            src="https://res.cloudinary.com/dhqpvur6n/image/upload/v1604483524/Chassis_Brakes/businessmen-brainstorming-in-the-workplace-vector_f3twkh.jpg"
            class=" rounded img-fluid hoverable wow fadeIn slower delay-0.5s" alt="IMG1">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content wow fadeInRight slower delay-0.5s">
          <br>
          <h3 style="margin-top: 10px;" id="verticalunderline-small"><strong>Who We are</strong></h3>
          <br>
          <p style="text-align: justify;"><strong>
            Arthnext is a FinTech enterprise offering a specialized ‘Digital Loan Origination System’ (LOS) solution for the Banking and Finance industry. With our deep domain understanding and through our learnings from industry best practices we continuously enhanced products to deliver the most relevant solutions to our clients. We provide total commitment to building lasting partnerships with our customers. Our headquarter, product development lab and a support centre are based in Pune. Our solution can be easily implemented and are capable of seamless integrations with Credit Bureaus, Public Registries, Alternate Data Points and Loan Management Systems.

            </strong>
          </p>

        </div>
      </div>

      <div class="row" style="margin-top: 5%;">
        <div class="col-lg-6 wow fadeInLeft slower delay-0.5s">
          <h3 style="margin-top: 10px;" id="verticalunderline-small"><strong>Our Mission</strong></h3>
          <br>
          <p style="text-align: justify;"><strong>
            Our mission is to enrich lives of individuals and businesses by driving intelligent operations with automated processes and high-end technology. We aim to build a process-driven, tech enabled and growth-oriented organization that supports financial institutions through Innovative software solutions.
            </strong>
          </p>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content">

          <img width="100%" height="auto"
            src="https://res.cloudinary.com/dhqpvur6n/image/upload/v1604484488/Chassis_Brakes/246-2462149_teamwork-vector-hd-png-download_ce5uy1.png"
            class=" rounded img-fluid hoverable wow fadeIn slower delay-0.5s" alt="IMG2">
        </div>
      </div>

      <div class="row" style="margin-top: 5%;">

        <div class="col-lg-6">
          <img width="100%" height="auto"  

            src="https://res.cloudinary.com/dhqpvur6n/image/upload/v1604484802/Chassis_Brakes/188-1887596_supply-and-demand-planning-illustration-hd-png-download_btzzlz.png"
            class=" rounded img-fluid hoverable wow fadeIn slower delay-0.5s" alt="IMG3">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content wow fadeInRight slower delay-0.5s">
          <h3 style="margin-top: 10px;" id="verticalunderline-small"><strong>Our Values</strong></h3>
          <br>
          <p style="text-align: justify;"><strong>We believe in excelling together. This forms the way our organization works to develop products and partner with customers to ensure their success. These values underpin everything we do. They demonstrate our commitment to create a strong corporate culture and long-term partnerships which deliver true value to the customers.</strong></p>
          <p style="text-align: justify;"><strong>
            Arthnext is driven by its five core values –



            </strong>
          </p>
          <div class="row">
            <p class=" col-md-6 wow fadeInRight slower" data-wow-delay="0.5s"><strong> <span
              class="material-icons icondot">fiber_manual_record</span> Customer Satisfaction</strong></p>
        <p  class=" col-md-6 wow fadeInRight slower" data-wow-delay="1s"><strong> <span
              class="material-icons icondot">fiber_manual_record</span>Quality</strong></p>
              <p class=" col-md-6 wow fadeInRight slower" data-wow-delay="1.5s"><strong> <span
                class="material-icons icondot">fiber_manual_record</span> Integrity</strong></p>
          <p class=" col-md-6 wow fadeInRight slower" data-wow-delay="2s"><strong> <span
                class="material-icons icondot">fiber_manual_record</span>Innovativeness</strong></p>
                <p class=" col-md-6 wow fadeInRight slower" data-wow-delay="2s"><strong> <span
                  class="material-icons icondot">fiber_manual_record</span>Teamwork</strong></p>
                 

          </div>
         
         
        </div>
      </div>
    </div>
  </section>

</main>


<div class="container mt-5">
  <div class="row">
    <div class="col-lg-12 section-title">
      <h2>WHY CUSTOMER CHOOSE US</h2>
      <p>A choice that makes the difference!</p>
      <br>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="cards">

        <div class="cardss wow fadeInLeft slower " id="verticalunderline-small2" data-wow-delay="1.5s">
          <span style="font-size: 70px;" class="material-icons avtar" id="gradient">
            access_time
          </span>
          <h5 style="text-align: center; font-weight: bold;">Smart &amp; Easy</h5>
          <!-- <p>Typography should be relevant and thought out. Type is so strong that it can</p> -->

        </div>
        <div class="cardss wow fadeInLeft slower  " id="verticalunderline-small2" data-wow-delay="1s">
          <span style="font-size: 70px;" class="material-icons avtar" id="gradient">
            speed
          </span>

          <h5 style="text-align: center; font-weight: bold;">Data Protection</h5>
          <!-- <p>Typography should be relevant and thought out. Type is so strong that it can</p> -->
        </div>
        <div class="cardss wow fadeInLeft slower " id="verticalunderline-small2" data-wow-delay="0.5s">
          <span style="font-size: 70px;" class="material-icons avtar" id="gradient">
            whatshot
          </span>
          <h5 style="text-align: center; font-weight: bold;">Quick Approval</h5>
          <!-- <p>Typography should be relevant and thought out. Type is so strong that it can</p> -->
        </div>
        <div class="cardss wow fadeInRight slower " id="verticalunderline-small2" data-wow-delay="2s">
          <span style="font-size: 70px;" class="material-icons avtar" id="gradient">
            insert_chart_outlined
          </span>
          <h5 style="text-align: center; font-weight: bold;">Cost Optimized LOS</h5>
          <!-- <p>Typography should be relevant and thought out. Type is so strong that it can</p> -->
        </div>
        <div class="cardss wow fadeInRight slower " id="verticalunderline-small2" data-wow-delay="2.5s">
          <span style="font-size: 70px;" class="material-icons avtar" id="gradient">
            autorenew
          </span>
          <h5 style="text-align: center; font-weight: bold;">NextGen Technology</h5>
          <!-- <p>Typography should be relevant and thought out. Type is so strong that it can</p> -->
        </div>
        <div class="cardss wow fadeInRight slower " id="verticalunderline-small2" data-wow-delay="3s">
          <span style="font-size: 70px;" class="material-icons avtar" id="gradient">
            task_alt
          </span>
          <h5 style="text-align: center; font-weight: bold;">Advanced Analytics</h5>
          <!-- <p>Typography should be relevant and thought out. Type is so strong that it can</p> -->
        </div>

      </div>
    </div>
  </div>
</div>

<!-- End Home Page -->



<!-- Begin Product -->

<div #product1 class="mb-4">

</div>

<main style="margin-top: 50px!important;">
  <section id="about" class="about">
    <div class="container">
      <!-- <a class="btn btn-white-view" [routerLink]="[ '/detailView',12345]">view</a> -->
      <div class="section-title">
        <h2> <a class="effect-underline wow fadeInDown slower " data-wow-delay="1s">Arthnext “SmartCredit” Loan Origination System (LOS)</a></h2>
        <p class="wow fadeInUp slower" data-wow-delay="1s">“Arthnext SmartCredit LOS" is a multi-channel, multi-point AI / ML enabled, scalable, cloud-based LOS for credit life cycle management. Our product helps customers to digitize the complete credit-lifecycle end to end, helping make better credit decisions faster to comprehensive loan servicing. </p>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <img width="100%" height="auto"
            src="https://media.istockphoto.com/vectors/human-resources-people-and-clipboard-with-job-candidates-list-and-vector-id1184240745?b=1&k=6&m=1184240745&s=612x612&w=0&h=mwUPQUBtPR3-w5fJA_qRRPK95Mt0K5PgLTd_OjxnSEM="
            class=" rounded img-fluid wow fadeInLeft slower " alt="IMG6">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content wow fadeInRight slower">
          <br>
          <div class="section-title">
          <h2 style="margin-top: 5px; "><strong>Lines of Business Covered</strong></h2>
          <br>
          </div>
         
          <div class="row">

          <div class=" col-md-6" >
            <span style="font-size: 50px; text-align: center;" class="material-icons avtar" id="gradient">
              account_balance
            </span>
  
            <h5 style="text-align: center; margin-top: -5%; font-weight: bold; color: #4b605c;">&nbsp;&nbsp;&nbsp; Lending</h5>
            <p style="text-align: center; " > |Retail Loans | MSME Loans | Corporate Loans | Micro Lending | Priority Sector Lending 
            | Rural Lending|</p> 
          </div>
          <!-- <p><strong>
            A) Lending: 
            </strong>
          </p>
          <p><strong>
            |Retail Loans | MSME Loans | Corporate Loans | Micro Lending | Priority Sector Lending 
            | Rural Lending|
             
            </strong>
          </p> -->

          <div class=" col-md-6" >
            <span style="font-size: 50px; text-align: center;" class="material-icons avtar" id="gradient">
              storage icon
            </span>
  
            <h5 style="text-align: center; margin-top: -5%; font-weight: bold; color: #4b605c;">Data Analytics </h5>
            <p style="text-align: center;"> | AI/ML Based Predictive Risk Modelling | IFRS 9 | Expected Credit Loss (ECL) Modelling 
              | Basel II/III Norms | Marketing Analytics | </p> 
          </div>
        </div>
          <!-- <p><strong>
            B) Data Analytics: 
            </strong>
          </p>
          <p><strong>
            | AI/ML Based Predictive Risk Modelling | IFRS 9 | Expected Credit Loss (ECL) Modelling 
            | Basel II/III Norms | Marketing Analytics |            
            </strong>
          </p> -->

        </div>
      </div>

      <div class="row " style="margin-top: 5%;margin-bottom: 10%;">
        <div class="col-lg-12 ">
          <div class="section-title">
            <h2 style="margin-top: 10px; text-align: center;"><strong>Key Differentiators</strong></h2>
          </div>
          

          <div class="row flex-center" style="margin-top: -4%;">
            <div class="col-lg-10 ">

              <p class="wow fadeIn slower" data-wow-delay="0.5s" style="padding-bottom: 10px;"><strong>
                  <span class="material-icons icon-check">check_circle</span> Channel Agnostic Loan Application
                  Process with offline and online capabilities including Dedupe.
                </strong>
              </p>
              <p class="wow fadeIn slower" data-wow-delay="1s" style="padding-bottom: 10px;"><strong>
                  <span class="material-icons icon-check">check_circle</span> Third party connectors to Credit
                  Bureau, Other Third-Party Data Points and Public Registries.
                </strong>
              </p>
              <p class="wow fadeIn slower" data-wow-delay="1.5s" style="padding-bottom: 10px;"><strong>
                  <span class="material-icons icon-check">check_circle</span> Configurable user-friendly business rule engine.
                </strong>
              </p>
              <p class="wow fadeIn slower" data-wow-delay="2s" style="padding-bottom: 10px;"><strong>
                  <span class="material-icons icon-check">check_circle</span> End-to-end encrypted data protection.
                </strong>
              </p>
              <p class="wow fadeIn slower" data-wow-delay="2.5s" style="padding-bottom: 10px;"><strong>
                  <span class="material-icons icon-check">check_circle</span> In built Document Management Solution.
                </strong>
              </p>
              <p class="wow fadeIn slower" data-wow-delay="3s" style="padding-bottom: 10px;"><strong>
                  <span class="material-icons icon-check">check_circle</span> Capability to host and execute IFRS9 and ECL models for regulatory compliance.
                </strong>
              </p>
              <p class="wow fadeIn slower" data-wow-delay="3.5s" style="padding-bottom: 10px;"><strong>
                  <span class="material-icons icon-check">check_circle</span> Data Analytics capabilities with distinctive MIS reporting’s.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 5%;">

        <div class="col-lg-6">
          <img width="100%" height="auto"
            src="https://gryphonnetworks.com/wp-content/uploads/2020/09/Screen-Shot-2020-09-15-at-12.34.15-PM.png"
            class=" rounded img-fluid " alt="IMG5">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content">
          <h3 style="margin-top: 10px;"><strong>Technical Highlights</strong></h3>
          <br>
          <p><strong>
              Arthnext LOS is developed using the latest technological platform and supports customization as per
              customer requirement. It supports interfacing with other applications in the enterprise eco system and
              enables straight through processing.

            </strong>
          </p>
          <p><strong>Some key features include:</strong></p>
          <p class="wow fadeInRight slower" data-wow-delay="0.5s"><strong> <span
                class="material-icons icondot">fiber_manual_record</span> Responsive User Interface:
              Smart, modern, and responsive for best view</strong></p>
          <p class="wow fadeInRight slower" data-wow-delay="1s"><strong> <span
                class="material-icons icondot">fiber_manual_record</span>Cloud Ready: Operating system and database independent.</strong></p>
          <p class="wow fadeInRight slower" data-wow-delay="1.5s"><strong> <span
                class="material-icons icondot">fiber_manual_record</span> Very Easy to Use even in
              offline mode.</strong></p>
          <p class="wow fadeInRight slower" data-wow-delay="2s"><strong> <span
                class="material-icons icondot">fiber_manual_record</span>Channel and smart device agnostic.</strong></p>

        </div>
      </div>
    </div>
  </section>
</main>



<div class="container-fluid">
  <h1 class="text-center py-5 section-title" style="color: #4b605c;">SINGLE PLATFORM WITH MULTIPLE BUSINESS BENEFITS
  </h1>
  <p class="text-center" style="margin-top: -3%; margin-bottom: 2%;"><strong>“Arthnext provides below business benefits to banks to optimize their lending solutions”</strong> </p>
  <div class="row ">
    <div class="col-md-3 col-sm-6 d-inline-flex">
      <div class="serviceBox">
        <div class="service-icon">
          <span class="material-icons" id="whiteicon">
            language
          </span>
        </div>
        <h3 class="titles">BUSINESS GROWTH</h3>
        <p class="description">Support for multiple product lines, multiple-channels with business analytics.</p>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 d-inline-flex">
      <div class="serviceBox red">
        <div class="service-icon ">
          <span class="material-icons" id="whiteicon">
            bolt
          </span>
        </div>
        <h3 class="titles">OPERATIONAL EFFICIENCY</h3>
        <p class="description">Enhanced operations and service levels with optimal cost.</p>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 d-inline-flex">
      <div class="serviceBox blue">
        <div class="service-icon">
          <span class="material-icons" id="whiteicon">
            stay_primary_portrait
          </span>
        </div>
        <h3 class="titles">BUSINESS AGILITY WITH ENHANCED MANAGEABILITY</h3>
        <p class="description">Faster time-to-market with new products launch.</p>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 d-inline-flex">
      <div class="serviceBox green">
        <div class="service-icon">
          <span class="material-icons" id="whiteicon">
            email
          </span>
        </div>
        <h3 class="titles">CUSTOMER SATISFACTION </h3>
        <p class="description">Straight-through processing for quick disbursal of loans and other customer requests.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- End Product -->

<!-- start customer -->
<div #customer1 style="margin-bottom: 100px !important;">
</div>
<main>
  <div class="container ">
    <div class="row">
      <div class="col-lg-12 section-title">
        <h2> <a class="effect-underline py-5">TESTIMONIAL</a></h2>
        <p>Every day the world is shifting more towards new digital and interactive experiences. With rapidly-evolving
          technologies, changing consumer preferences and multiple competing channels, many organizations struggle
          with how to transform to meet the challenges of this new, always connected digital world. Arthnext Ventures
          enables organizations to create engaging and consistent digital experiences across every touchpoint,
          providing new opportunities for growth. Look at what our customers speak about us</p>
      </div>
      <div class="col-lg-12 section-title">
        <h2 style="font-weight: bold"> Leading Loan Aggregator</h2>
        <!-- <p><strong>A new age fast growing private bank in India</strong></p> -->
        <p>“We would like to thank Arthnext for the continued support in improving our business processes. Your
          responsiveness has helped us greatly. Best wishes and looking forward to our relationship going from
          strength to strength”.
        </p>
      </div>
    </div>
  </div>
</main>
<!-- End customer-->

<!-- Begin Contact Us -->
<div #contactus1></div>
<main>
  <div class="container ">
    <!--Section: Content-->
    <section class="dark-grey-text">
      <!-- Section heading -->
      <h2 class="font-weight-bold text-center  py-3 section-title" style="color: #4b605c;">CONTACT US</h2>
      <!-- Section description -->
      <p class="text-center w-responsive mx-auto pb-5">Please fill out the quick form and we will be in touch with
        lightning speed.</p>

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-5 mb-lg-0 pb-lg-3 mb-4">

          <!-- Form with header -->
          <div class="card" style="background-color: #fafafa;">
            <div class="card-body">
              <!-- Header -->
              <div class="form-header" style="text-align: center;">
                <h3 class="mt-2"> Write to us</h3>
              </div>
              <p class="dark-grey-text" style="text-align: center;">We love to hear from you!</p>
              <!-- Body -->

              <form #login="ngForm">

                <mat-form-field class="col-md-6">
                  <input name="name" ngModel required matInput placeholder="Your Name">
                </mat-form-field>

                <mat-form-field class="col-md-6">
                  <input name="email" ngModel required matInput placeholder="Your email">
                </mat-form-field>

                <mat-form-field class="col-md-12">
                  <input name="contact" type="tel" maxlength="10" minlength="10" ngModel required matInput
                    placeholder="Your Contact">
                </mat-form-field>

                <mat-form-field class="col-md-12">
                  <input name="subject" ngModel required matInput placeholder="Subject">
                </mat-form-field>

                <mat-form-field class="example-full-width" class="col-md-12">
                  <mat-label>Leave a Message</mat-label>
                  <textarea name="msg" ngModel required matInput placeholder="Ex. It makes me feel..."></textarea>
                </mat-form-field>

                <div class="text-center">
                  <button type="button" class="btn btn- primary-color-dark" [disabled]="!login.valid"
                  (click)="submit(login.value);login.reset()">Submit</button>
                </div>
              </form>
            </div>
          </div>
          <!-- Form with header -->
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-lg-7">
          <!--Google map-->
          <div id="map-container-section" class="z-depth-1-half map-container-section mb-4" style="height: 380px">
            <iframe title="Map" width="100%" height="380" frameborder="0" scrolling="no" marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Mahatma%20Society+(Arthnext)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
          </div>
          <div class="row text-center">
            <div class="col-md-6">
              <div class="text-danger">
                <i class="material-icons">place</i>
              </div>
              <p>L701, Hillview Residency, Mahatma Society Kothrud,
                Pune – 411 038. Maharashtra, India.</p>
            </div>
            <div class="col-md-3">
              <div class="text-danger">
                <i class="material-icons">phone</i>
              </div>
              <p>+91 9834213726
              </p>
            </div>
            <div class="col-md-3">
              <div class="text-danger">
                <i class="material-icons">email</i>
              </div>
              <p>sales&#64;arthnext.com
              </p>
            </div>
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
  </div>
</main>
<!-- End Contactus -->

<!-- //footer -->
<footer class="section bg-footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <h3 class="footer-heading text-uppercase text-white">Information</h3>
        <ul class="list-unstyled footer-link mt-4">
          <li><a (click)="scrollToElement(home1)">Home</a></li>
          <li><a (click)="scrollToElement(product1)">Products</a></li>
          <li><a (click)="scrollToElement(customer1)">Customers</a></li>
          <li><a (click)="scrollToElement(contactus1)">Contact Us</a></li>
        </ul>
      </div>

      <div class="col-lg-3">
        <h3 class="footer-heading text-uppercase text-white">Resources</h3>
        <ul class="list-unstyled footer-link mt-4">
          <li><a href="#">Management Board</a></li>
          <li><a href="#">Advisory Board</a></li>
          <li><a href="#">News</a></li>
          <li><a href="#">Blogs</a></li>
        </ul>
      </div>

      <div class="col-lg-2">
        <h3 class="footer-heading text-uppercase text-white">Help</h3>
        <ul class="list-unstyled footer-link mt-4">
          <li><a href="#">Schedule Demo</a></li>
          <li><a href="#">Terms of Services</a></li>
          <li><a href="#">Privacy Policy</a></li>
        </ul>
      </div>

      <div class="col-lg-4">
        <h3 class="footer-heading text-uppercase text-white">Contact Us</h3>
        <p class="contact-info mt-4 footer-link" ><a href="#">L701, Hillview Residency, Mahatma Society Kothrud, Pune – 411 038.
          Maharashtra, India.</a></p>
        <p class="contact-info footer-link"><a href="#">+91 9834213726 / sales&#64;arthnext.com</a></p>
        
        <div class="mt-5">
          <ul class="list-inline">
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>